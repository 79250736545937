import React, {useState} from 'react';
import {
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
  View,
  StyleSheet,
} from 'react-native';
import {PopListItem} from '../../app.service';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {goTo} from '@/utils';

export interface PopListProps {
  popConfigList: PopListItem[];
  onClose?: () => void; // 新增的回调属性
}

const VipClubList: React.FC<PopListProps> = ({popConfigList, onClose}) => {
  const {screenWidth} = useScreenSize();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleScroll = (event: any) => {
    const contentOffsetX = event.nativeEvent.contentOffset.x;
    const index = Math.floor(contentOffsetX / (screenWidth / 1.05));
    setCurrentIndex(index);
  };

  return (
    <View
      style={{
        alignItems: 'center',
        height: screenWidth / 0.79 + 30,
      }}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        pagingEnabled
        onScroll={handleScroll}
        style={[{width: screenWidth / 1.05}]}>
        {popConfigList.map((item, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              if (!item.popUrl) return;

              const popUrl = item.popUrl.trim();

              if (popUrl.startsWith('route:')) {
                try {
                  const routeData = JSON.parse(
                    popUrl.replace(/^route:/, '').trim(),
                  );

                  if (routeData.type === 'rn' && routeData.routeName) {
                    const params =
                      routeData.routeParams &&
                      Object.keys(routeData.routeParams).length > 0
                        ? routeData.routeParams
                        : undefined;
                    goTo(routeData.routeName, params);
                    // 调用关闭回调
                    onClose && onClose();
                    return;
                  }
                } catch (error) {
                  console.error('error', error);
                }
              }

              // 外部 URL 跳转
              Linking.openURL(popUrl);
              // 调用关闭回调
              onClose && onClose();
            }}>
            <Image
              source={{uri: item.popImg}}
              style={{
                width: screenWidth / 1.05,
                height: screenWidth / 0.79,
                zIndex: 2,
                resizeMode: 'contain',
                borderRadius: screenWidth / 22,
              }}
            />
          </TouchableOpacity>
        ))}
      </ScrollView>

      <View
        style={[
          styles.indicatorContainer,
          {width: screenWidth / 1.05, height: 20},
        ]}>
        {popConfigList.map((_, index) => (
          <View
            key={index}
            style={[
              styles.indicator,
              currentIndex === index
                ? styles.activeIndicator
                : styles.inactiveIndicator,
            ]}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  indicatorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 5,
  },
  activeIndicator: {
    backgroundColor: '#00BB8F',
  },
  inactiveIndicator: {
    backgroundColor: '#acacad',
  },
});

export default VipClubList;
